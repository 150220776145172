export class Promotion {
  id;
  /** @type {string} */
  name;
  /** @type {'discount' | 'promocode'} */
  type;
  /** @type {'draft' | 'active' | 'paused' | 'expired' | 'canceled'} */
  status;
  /** @type {number} */
  discount;
  /** @type {number} */
  max_discount_amount;
  /** @type {string} */
  sales_channel;
  /** @type {Date} */
  start_date;
  /** @type {Date} */
  end_date;
  hubs;
}