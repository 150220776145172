<template>
  <v-dialog v-model="isAddProductModalVisible" max-width="750" persistent>
    <template>
      <v-card>
        <v-toolbar>
          <div
            class="d-flex align-center justify-space-between"
            style="width: 100%"
          >
            <h3 class="font-weight-regular text-uppercase">
              Select product batches
            </h3>
            <v-spacer></v-spacer>
            <div>
              <v-icon @click="toggleAddProductModal">mdi-close</v-icon>
            </div>
          </div>
        </v-toolbar>
        <v-card-text>
          <div class="d-flex" style="gap: 20px; width: 100%">
            <v-img
              v-if="product.image"
              :src="product.image.url"
              max-height="80"
              max-width="80"
            />
            <v-img
              v-else
              src="@/assets/product.png"
              max-height="50"
              max-width="50"
            />
            <div>
              <p class="mb-0">{{ product.name }}</p>
              <p class="text--disabled subtitle-2">
                ID: {{ product.id }} * Barcode: {{ product.barcode }}
              </p>
            </div>
          </div>
          <v-card>
            <v-card-title>
              Batches
              <v-text-field
                v-model="search"
                label="Search"
                outlined
                dense
                hide-details
                clearable
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="product.lots"
              :search="search"
              :items-per-page="5"
            >
              <template v-slot:item.hub_id="{ item }">
                <span>{{
                  hubs.find((hub) => hub.id === item.hub_id)?.name
                }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  @click="toggleSelectBatch(item)"
                  small
                  outlined
                  color="primary"
                  :disabled="item.qte_in_lot <= 0 || isLotExpired(item)"
                  v-if="!isLotSelected(item)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn
                  @click="toggleSelectBatch(item)"
                  small
                  color="primary"
                  v-if="isLotSelected(item)"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
          <div class="d-flex">
            <v-btn
              class="mt-2 ml-auto"
              outlined
              color="primary"
              @click="toggleAddProductModal"
            >
              Close
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { Promotion } from "../promotion.class";
export default {
  props: {
    isAddProductModalVisible: Boolean,
    toggleAddProductModal: Function,
    product: Object,
    promotion: Promotion,
  },
  computed: {
    ...mapGetters({
      hubs: "hubs/activeHubs",
      promotionDetails: "promotions/promotionDetails",
    }),
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Batch Number", value: "lot_number" },
        { text: "Hub", value: "hub_id" },
        { text: "Quantity", value: "qte_in_lot" },
        { text: "Expiration Date", value: "expiration_date" },
        {
          text: this.$t("actions"),
          value: "actions",
          align: "end",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    toggleSelectBatch(lot) {
      this.$store.dispatch("promotions/toggleSelectBatch", {
        promotion: this.promotion,
        product: this.product,
        lot_id: lot.id,
      });
    },
    isLotSelected(item) {
      const productProp = this.product;
      const promotionProduct = this.promotionDetails?.products.find(
        (product) => product.product_id === productProp.id
      );
      const lot = promotionProduct?.lots.find((lot) => lot.id === item.id);
      return !!lot;
    },
    isLotExpired(item) {
      return (
        item.expiration_date &&
        new Date(item.expiration_date).getTime() < new Date().getTime()
      );
    },
  },
};
</script>